<template>
    <div class="chooseHospital">
        <div class="headTop">
            <Header backType="white" backEvent="true" @backEvent="backPage" :back="true"></Header>
            <div class="publicHead mt-2 mb1-5">
                <p>
                    <span>选择医院</span>
                </p>
            </div>
            <div class="opSearchRow">
                <Svgs color="#C0C4CD" classes="opSearchSvg" name="iconsousuo"></Svgs>
                <input type="text" placeholder="请输入医院名称" v-model="hospitalName" />
            </div>
        </div>
        <ScrollBar class="opOrderListScroll2" 
                @onTop="scrollTop" 
                @onBottom="scrollBottom"
                :loadType="loadType">
            <div slot="scrollBar">
                <div class="hospitalList" v-if="eventList.length">
                    <ul>
                        <li v-for="item in eventList" :key="item.rowId" @click="next(item)">{{item.name}}</li>
                    </ul>
                </div>
                <div class="noData" v-if="!eventList.length&&loadType==''&&!isFirst">
                    暂无数据
                </div>
            </div>
        </ScrollBar>
    </div>
</template>

<script>
import {SmartStorage} from 'smart-core-util'
import { miceService } from "@/service/miceService.js";
export default {
    data(){
        return{
            hospitalName:'',
            loadType: '',
            isFirst:true,
            pageIndex: 1,
            pageSize: 20,
            eventList:[],
            total:null
        }
    },
    created(){
        this.getHospital();
    },
    watch: {
        hospitalName: function() {
            this.getHospital(true)
        }
    },
    mounted(){
        // this.setListStyle();
    },
    methods:{
        backPage() {
            this.$router.push({path:'/recommendShop',query:{searchCity: true}})
        },
        getHospital(refresh){
            if(refresh) {
                this.pageIndex = 1
            }
            let params = {
                cityId: SmartStorage.get('regionId'), //根据前面选择的城市guid
                pageIndex: this.pageIndex,
                pageSize: this.pageSize,
                searchInput: this.hospitalName
            }
            miceService.PagingTenantHospital(params).then(res => {
                this.isFirst = false;
                if (res.success) {
                    this.eventList = refresh ? res.content.rows : this.eventList.concat(res.content.rows); 
                    this.total = res.content.total;
                    this.loadType = '';
                }
            });
        },
        scrollTop(filter) {
            this.loadType = 'top';
            this.pageIndex = 1;
            if (filter) {
                this.eventList = [];
            }
            this.getHospital(true);
        },
        scrollBottom() {
            if (this.pageSize * this.pageIndex >= this.total) {
                this.loadType = 'none';
            } else {
                this.loadType = 'bottom';
                this.pageIndex += 1;
                this.getHospital();
            }
        },
        setListStyle() {
            let head = document.getElementsByClassName('headTop')[0].offsetHeight;
            let _this = this;
            setTimeout(function () {
                _this.setListStyle();
            }, 100);
            let hei = window.innerHeight - head; 
            this.listStyle = {
                height: hei + 'px'
            }
        },
        next(item){
            this.$router.push({path:'/gaoDeShopList'})
            SmartStorage.set('hospitalId',item.rowId)
        },
    }
}
</script>

<style lang="scss">
.headTop{
    padding-bottom: .1rem;
}
.publicHead{
    margin-bottom: .25rem!important;
}
.opOrderListScroll2{
    top: 1.45rem!important;
    height: calc(100% - 1.45rem)!important;
    overflow: scroll;
    position: absolute;
    bottom: 0;
    width: 100%;
    top: 1.7rem;
}
.opSearchRow {
//   width: 100%;
  padding: 0 .25rem 0 .25rem;
//   margin: .25rem 0;
  position: relative;
}
.opSearchRow:after {
  display: block;
  clear: both;
  content: "";
  visibility: hidden;
  height: 0
}
.opSearchRow input {
  border-radius: .2rem;
//   box-shadow: 5px 5px .15rem #F5F5F5;
  border: 1px solid #F5F5F5;
  background: #FAFAFA;
  height: .36rem;
  line-height: .36rem;
  width: 90%;
  float: left;
  text-align: left;
  outline: none;
  padding-left: .35rem;
  font-size: .12rem;
}
.opSearchSvg {
  position: absolute;
  left: .38rem;
  top: .11rem;
  width: .15rem !important;
  height: .15rem !important;
}
.hospitalList ul {
    background: #fff;
}
.hospitalList ul li{
    text-align: left;
    padding: .16rem 0;
    margin: 0 .26rem;
    border-bottom: 1px solid #f5f5f5;
    color: #13161B;
    font-size: .14rem;
}
.noData{
    margin-top: 2.5rem;
}
</style>